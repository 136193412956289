import React from 'react'
import { graphql } from 'gatsby'
import PageTitle from "../components/PageTitle";
import Slash from '../components/Slash/Slash'
import Layout from '../components/Layout'
import Container from '../components/Container'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image'
import Name from '../components/Item/Name'
import Body from '../components/Item/Body'
import ItemWrapper from '../components/Item/ItemWrapper'
import SimpleSlider from '../components/Slider'
import Information from '../components/Item/Information'
import MemberCard from '../components/MemberCard'
import CardList from '../components/CardList'
import { startCase } from 'lodash'
import SEO from '../components/SEO'

const SpaceTemplate = ({ data, pageContext }) => {
  const {
    email,
    description,
    image,
    name,
    locationAddress,
    locationLink
  } = data.contentfulSpace

  const members = data.allContentfulMember.edges

  let ogImage
  try {
    ogImage = image[0].file.url
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO title="About" image={ogImage} />
      <Container>
        <PageTitle>about</PageTitle>
        <Slash />
        <ItemWrapper>
          <SimpleSlider>
            {
              image.map((image) =>
                <Img
                  fluid={image.fluid}
                  alt={image.title}
                  backgroundColor={'#eeeeee'}
                />
              )
            }
          </SimpleSlider>
          <Name padding="0 0 1.5em">{name}</Name>
          <Information>
            <ul>
              <li>
                {locationAddress}{" "}<br />
                →{" "}
                <a
                  href={locationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Map
                </a><br />
                <p style={{ fontSize: '0.8em' }}>JR/新小平駅より徒歩20分、および西武多摩湖線/青梅街道駅より徒歩10分。</p>
              </li>
              <li>{email}</li>
            </ul>
          </Information>
          <Body>
            {documentToReactComponents(description.json)}
          </Body>
        </ItemWrapper>
        <div style={{ marginTop: "6em" }} />
        <PageTitle>member</PageTitle>
        <Slash />
        <ItemWrapper>
          <CardList>
            {members.map(({ node: member }) => (
              <MemberCard key={member.id} member={member} />
            ))}
          </CardList>
        </ItemWrapper>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulSpace(slug: { eq: $slug }) {
      email
      image {
        fluid(maxWidth: 800, maxHeight: 566, resizingBehavior: PAD) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
        file {
          url
        }
      }
      locationAddress
      locationLink
      name
      description {
        json
      }
    }
    allContentfulMember(sort: {fields: sort, order: ASC}) {
      edges {
        node {
          name
          facebook
          image {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          instagram
          portfolio
          profile {
            profile
          }
          slug
          twitter
          website
        }
      }
    }
  }
`

export default SpaceTemplate
