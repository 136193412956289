import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Body from '../components/Item/Body'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Information from '../components/Item/Information'
import Name from './Item/Name'

const Member = styled.li`
  position: relative;
  margin: 0 0 4em 0;
  width: 100%;
  transition: background 0.2s;
  &:last-child {
    margin: 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
`

const SNSList = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin: 1em 0 3em;
  li {
    margin-right: 1em;
  }
  svg {
    margin: 4px auto;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 24px;
    width: 24px;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
  }
`

const List = styled.ul`
  display: inline-block;
  li {
    margin: 0 1em 0 0;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
  }
  p {
    font-weight: 600;
    font-size: 1em;
  }
`

const MemberCard = (props) => {
  const {
    slug,
    instagram,
    facebook,
    portfolio,
    profile,
    twitter,
    website,
    name,
    image
  } = props.member
  return (
    <>
      {image && (
        <Member>
          <Img fluid={image.fluid} backgroundColor={'#eeeeee'} />
          <Name padding={"1em 1em 0 0"}>{name}</Name>
          <Body style={{ marginTop: "1em", marginBottom: "0.5em", lineHeight: "1.5" }}>{profile.profile}</Body>
          {(website || portfolio) && (
            <Information padding={"0.5em 1em"}>
              <List>
                {website && (
                  <li>
                    <a
                      href={website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>
                        → website
                    </p>
                    </a>
                  </li>
                )}
                {portfolio && (
                  <li>
                    <a
                      href={portfolio}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>
                        → portfolio
                    </p>
                    </a>
                  </li>
                )}
              </List>
            </Information>
          )}
          <SNSList>
            {twitter && (
              <li>
                <a
                  href={twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
            )}
            {facebook && (
              <li>
                <a
                  href={facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
            )}
            {instagram && (
              <li>
                <a
                  href={instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
            )}
          </SNSList>
        </Member>
      )}
    </>
  )
}

export default MemberCard
